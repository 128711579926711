<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 기본정보 -->
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group v-show="!resultFlag&&!apprFlag" outline >
            <!-- 저장 -->
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveEducation"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              :required="true" 
              :disabled="popupParam.disabled || resultFlag"
              :editable="editable" 
              type="edit" 
              name="plantCd" 
              v-model="eduInfo.plantCd"
              @datachange="yearChange" />
          </div>
          <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
            <!-- 교육년도 -->
            <c-datepicker
              :required="true" 
              :editable="editable"
              :disabled="popupParam.disabled || resultFlag"
              label="LBL0002847"
              type="year"
              name="educationYear"
              v-model="eduInfo.educationYear"
              @datachange="yearChange">
            </c-datepicker>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 연간교육계획 목록 -->
      <!-- 연간교육계획을 할 교육과정을 추가하세요. -->
      <c-table
        ref="table"
        title="LBL0002858"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        :editable="editable&&!resultFlag&&!apprFlag"
        :noDataLabel="$message('MSG0000859')"
        selection="multiple"
        cardClass="topcolor-orange"
        rowKey="eduEducationId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 교육과정추가 -->
            <c-btn v-if="editable && !popupParam.disabled && !resultFlag &&!apprFlag" :showLoading="false" label="LBL0002859" icon="add" @btnClicked="addSelect" />
            <c-btn v-if="editable && popupParam.disabled && !resultFlag &&!apprFlag" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addRow" />
            <c-btn v-if="editable && grid.data.length > 0 && !resultFlag &&!apprFlag" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="removeRow" />
          </q-btn-group>
        </template>
        <template slot="suffixTitle">
          <font v-if="yearEdu.sysApprovalRequestId" class="text-negative" style="font-size:0.8em;font-weight:300;">
            <!-- {{eduInfo.educationYear}}년 {{yearEdu.plantName}} 연간교육계획은 현재 <b>{{yearEdu.approvalStatusName}}</b> 상태입니다. -->
          {{suffixLabel}}
          </font>
        </template>
      </c-table>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'annual-education-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        disabled: false,
        educationYear: '',
        eduCourseId: '',
        educationMonth: '',
        plantCd: '',
        stepFlag: '',
        approvalStatusCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      eduInfo: {
        plantCd: null,
        educationYear: '',
      },
      standardInfo: {
        eduCourseId: '',
      },
      yearEdu: {
        eduEducationYearPlanId: '',  // 연간교육 일련번호
        plantCd: '',  // 사업장 코드
        plantName: '',  // 사업장
        educationYear: '',  // 교육년도
        sysApprovalRequestId: '',  // 결재요청번호
        approvalStatusCd: '', // 결재관련 결재상태
        approvalStatusName: '',
      },
      grid: {
        columns: [],
        data: [],
        height: '800px'
      },
      yearEduDetailUrl: '',
      yearListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      suffixLabel: '',
    };
  },
  watch: {
  },
  computed: {
    resultFlag() {
      /**
       * 결과로 들어온 경우 해당 팝업에서 쓰기권한 해제
       */
      return this.popupParam.stepFlag === 'Y'
    },
    apprFlag() {
      return this.popupParam.approvalStatusCd === 'ASC0000001' || this.yearEdu.approvalStatusCd === 'ASC0000001'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.edu.annual.save.url;
      this.deleteUrl = transactionConfig.sop.edu.annual.delete.url;
      this.yearEduDetailUrl = selectConfig.sop.edu.yearEdu.get.url;
      this.yearListUrl = selectConfig.sop.edu.annual.get.url;
      // list setting
      this.eduInfo.educationYear = this.popupParam.educationYear
      
      if (this.popupParam.eduCourseId) {
        this.eduInfo.plantCd = this.popupParam.plantCd
      }
      this.getList();
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('EDU_CLASS_CD').then(_classResult => {
        this.$comm.getComboItems('EDU_METHOD_CD').then(_classMethod => {
            this.grid.columns = [
            {
              name: 'educationCourseName',
              field: 'educationCourseName',
              required: true,
              innerBtn: true,
              btns: [
                { label: '', icon: 'add', color: 'orange', tooltip: this.$comm.getLangLabel('LBL0002859') },
              ],
              // 교육과정
              label: 'LBL0002930',
              align: 'center',
              style: 'width:120px', 
              sortable: false,
              // rowspan: 5,
            },
            {
              name: 'educationName',
              field: 'educationName',
              required: true,
              // 교육명
              label: 'LBL0002929',
              align: 'left',
              type: 'text',
              style: 'width:200px', 
              sortable: false,
              // rowspan: 5,
            },
            {
              name: 'educationTypeCd',
              field: 'educationTypeCd',
              setHeader: true,
              required: true,
              // 교육구분
              label: 'LBL0002935',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: _classResult,
              sortable: false,
            },
            {
              name: 'mainTargetAudience',
              field: 'mainTargetAudience',
              // 주요 대상자
              label: 'LBL0002814',
              type: 'text',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'educationDate',
              field: 'educationDate',
              required: true,
              // 교육기간
              label: 'LBL0002829',
              range: true,
              minuteStep: 10,
              type: 'date',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              name: 'educationMethodCd',
              field: 'educationMethodCd',
              setHeader: true,
              required: true,
              // 교육방법
              label: 'LBL0002936',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: _classMethod,
              sortable: false,
            },
            {
              name: 'educationLocation',
              field: 'educationLocation',
              required: true,
              // 교육장소
              label: 'LBL0002839',
              type: 'text',
              style: 'width:100px',
              align: 'left',
              sortable: false,
            },
          ]
        });
      });
    },
    getList() {
     if (this.eduInfo.plantCd && this.eduInfo.educationYear) {
        this.getYearEdu();
        this.$http.url = this.yearListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.eduInfo.plantCd,
          eduCourseId: this.popupParam.eduCourseId,
          educationMonth: this.popupParam.educationMonth,
          stepFlag: this.popupParam.stepFlag,
          educationYear: this.eduInfo.educationYear,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.$_.forEach(this.grid.data, _item => {
            _item.educationDate = [_item.educationStartDate, _item.educationEndDate]
          })
        },);
      } else {
        this.grid.data = [];
        Object.assign(this.$data.yearEdu, this.$options.data().yearEdu);
      }
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.standardInfo = this.$_.clone(_result.data);
        },);
      } else {
        Object.assign(this.$data.standardInfo, this.$options.data().standardInfo);
      }
    },
    getYearEdu() {
      // 전체조회
      this.$http.url = this.$format(this.yearEduDetailUrl, this.eduInfo.plantCd, this.eduInfo.educationYear);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.yearEdu = _result.data;
        this.suffixLabel = this.$comm.getLangLabel('LBL0002849', {s1: this.eduInfo.educationYear, s2: this.yearEdu.plantName, s3: this.yearEdu.approvalStatusName})
      },);
    },
    yearChange() {
      /**
       * 2022-05-12 kdh
       * 연간 교육계획 결재를 진행함으로 인해 계속적으로 교육 데이터가 들어가지 않도록 조치
       * 
       *  1. this.popupParam.educationMonth, this.popupParam.stepFlag의 값을 null처리
       *  2. 교육 데이터 조회
       *  3. 연간 교육계획 데이터 조회
       */
      this.popupParam.educationMonth = null;
      this.popupParam.stepFlag = '';
      this.getList();
    },
    addSelect() {
      this.popupOptions.title = 'LBL0002860'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$_.forEach(data.selectData, _item => {
          let index = this.$_.findIndex(this.grid.data, {
            eduCourseId: _item.eduCourseId,
          });
          if (index === -1) {
            this.grid.data.splice(0, 0, {
              educationCourseName: _item.educationCourseName, // 교육과정명
              // educationTypeCd: _item.educationTypeCd, // 교육구분
              eduEducationId: uid(),
              educationMonth: null,
              educationLocation: '',
              educationMethodCd: null,
              educationName: '', 
              editFlag: 'C',

              // 교육과정 마스터 데이터 
              eduCourseId: _item.eduCourseId, // 교육과정 key
              educationCycle: _item.educationCycle, // 교육주기
              educationKindCdLarge: _item.educationKindCdLarge, // 교육종류 대
              educationKindCdSmall: _item.educationKindCdSmall, // 교육종류 소
              educationPurpose: _item.educationPurpose, // 학습목적
              legalEducationFlag: _item.legalEducationFlag, // 법정여부
              mainTargetAudience: _item.mainTargetAudience, // 주요대상자
              relationLaws: _item.relationLaws, // 관련법규
              educationTime: _item.educationTime, // 교육시간
              estimatedEducationExpenses: _item.estimatedEducationExpenses // 교육시간
            })
          }
        })
      }
    },
    addRow() {
      // let checkCourse = Boolean(this.standardInfo.educationTypeCd)
      this.grid.data.push({
        educationCourseName: this.standardInfo.educationCourseName, // 교육과정명
        educationTypeCd: this.standardInfo.educationTypeCd, // 교육구분
        eduEducationId: uid(),
        educationMonth: null,
        educationLocation: '',
        educationMethodCd: null,
        educationName: '', 
        editFlag: 'C',

        // 교육과정 마스터 데이터 
        eduCourseId: this.standardInfo.eduCourseId, // 교육과정 key
        educationCycle: this.standardInfo.educationCycle, // 교육주기
        educationKindCdLarge: this.standardInfo.educationKindCdLarge, // 교육종류 대
        educationKindCdSmall: this.standardInfo.educationKindCdSmall, // 교육종류 소
        educationPurpose: this.standardInfo.educationPurpose, // 학습목적
        legalEducationFlag: this.standardInfo.legalEducationFlag, // 법정여부
        mainTargetAudience: this.standardInfo.mainTargetAudience, // 주요대상자
        relationLaws: this.standardInfo.relationLaws, // 관련법규
        educationTime: this.standardInfo.educationTime, // 교육시간
        estimatedEducationExpenses: this.standardInfo.estimatedEducationExpenses // 교육시간
      })
    },
    saveEducation() {
      if (!this.eduInfo.plantCd) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000860', // 사업장을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.eduEducationYearPlanId = this.yearEdu.eduEducationYearPlanId,
              _item.plantCd = this.eduInfo.plantCd,
              _item.educationYear = this.eduInfo.educationYear,
              _item.educationStartDate = _item.educationDate[0],
              _item.educationEndDate = _item.educationDate[1],
              _item.regUserId = this.$store.getters.user.userId
              _item.chgUserId = this.$store.getters.user.userId
            })
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { eduEducationId: item.eduEducationId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    innerBtnClicked(col, props) {
      this.grid.data.splice(props.rowIndex, 0, {
        eduEducationId: uid(),
        key: props.row.key, 
        educationCourseName: props.row.educationCourseName, 
        educationTypeCd: props.row.educationTypeCd,
        educationMonth: null,
        educationLocation: props.row.educationLocation,
        educationMethodCd: props.row.educationMethodCd,
        editFlag: 'C',
        eduCourseId: props.row.eduCourseId, // 교육과정 key
        educationCycle: props.row.educationCycle, // 교육주기
        educationKindCdLarge: props.row.educationKindCdLarge, // 교육종류 대
        educationKindCdSmall: props.row.educationKindCdSmall, // 교육종류 소
        educationPurpose: props.row.educationPurpose, // 학습목적
        legalEducationFlag: props.row.legalEducationFlag, // 법정여부
        mainTargetAudience: props.row.mainTargetAudience, // 주요대상자
        relationLaws: props.row.relationLaws, // 관련법규
        educationTime: props.row.educationTime, // 교육시간
        estimatedEducationExpenses: props.row.estimatedEducationExpenses // 교육시간
      });
    },
  }
};
</script>